import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import createSession from '../api/session';
import InputPassword from '../components/InputPassword';
import navigateTo from '../helpers/navigation';
import LoadingButton from '../components/LoadingButton';
import useGianoStore from '../store/store';
import { PUBLIC_URL } from '../constants';
import type { OAuthProvider } from '../api/oAuth';
import { getOAuthProviders } from '../api/oAuth';
import logger from '../logger';

import logo from '../img/logo192.png';

const H1 = styled('h1')``;
const Form = styled('form')``;
const Img = styled('img')``;

const FormTitle = (): JSX.Element => {
  return (
    <H1
      sx={{
        textAlign: 'center',
        fontSize: '28px',
        lineHeight: 1.57,
        fontWeight: 'bold',
        color: 'primary.main',
        margin: '0 0 40px',
      }}
    >
      <Img src={logo} alt='logo' width={20} sx={{ mr: 1 }} />
      VoiSmart
    </H1>
  );
};

export default function Login(): JSX.Element {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [submitEnabled, setSubmitEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [oAuthProviders, setOAuthProviders] = React.useState<OAuthProvider[]>([]);
  const intl = useIntl();
  const navigate = useNavigate();
  const updateNotification = useGianoStore((state) => state.updateNotification);
  const updateError = useGianoStore((state) => state.updateError);

  React.useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchData = async (): Promise<void> => {
      try {
        const response = await getOAuthProviders();
        if ('status' in response) {
          logger.error(`Error fetching OAuth server metadata, status: ${response.status}`);
          return;
        }
        if (isMounted) {
          setOAuthProviders(response);
        }
      } catch (error) {
        logger.error('Error fetching OAuth server metadata', error);
      }
    };

    void fetchData();

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, []);

  // TODO: separate logic and presentation
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e): void => {
    if (username && password) {
      setSubmitEnabled(false);
      setLoading(true);
      createSession(username, password)
        .then((res) => {
          switch (res.kind) {
            case 'authorized': {
              if ('next_action' in res) {
                switch (res.next_action?.action) {
                  case 'CHANGE_PASS': {
                    updateNotification('successRedirectingToChangePassword');
                    logger.info('redirecting to change password');
                    navigate('/change-password');
                    return;
                  }
                  case 'SETUP_TOTP': {
                    updateNotification('successRedirectingToSetup2FA');
                    logger.info('redirecting to setup 2FA');
                    navigate('/totp-config');
                    return;
                  }
                  case 'VERIFY_TOTP': {
                    updateNotification('successRedirectingToValidate2FA');
                    logger.info('redirecting to validate 2FA');
                    navigate('/validate-totp');
                    return;
                  }
                }
              }
              updateNotification('loginSuccess');
              navigateTo(PUBLIC_URL);
              return;
            }
            case 'unauthorized': {
              updateError('unauthorized');
              return;
            }
            case 'error': {
              updateError('error');
              return;
            }
            default: {
              logger.trace('got unexpected response from server', res);
              return assertNever(res);
            }
          }
        })
        .finally(() => {
          setLoading(false);
          setSubmitEnabled(true);
        });
    }
    e.preventDefault();
  };

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const insertedUsername = e.target.value;
    setUsername(insertedUsername);
    maybeEnableSubmit(insertedUsername, password);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const insertedPassword = e.target.value;
    setPassword(insertedPassword);
    maybeEnableSubmit(username, insertedPassword);
  };

  const maybeEnableSubmit = (u: string, p: string): void => {
    if (u && p) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  };

  return (
    <div className='form'>
      <FormTitle />
      <Form
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '16px',
        }}
      >
        <TextField
          autoFocus
          className='input'
          id='username'
          onChange={handleUsernameChange}
          required
          placeholder='admin@example.voismart.com'
          value={username}
          label={intl.formatMessage({ id: 'username' })}
          variant='outlined'
          disabled={loading}
        />
        <InputPassword
          id='password'
          value={password}
          onChange={handlePasswordChange}
          required
          label={intl.formatMessage({ id: 'password' })}
          disabled={loading}
        />
        <div>
          <LoadingButton disabled={!submitEnabled} loading={loading}>
            <FormattedMessage id='login' />
          </LoadingButton>
        </div>
      </Form>
      <div className='oauth-providers'>
        {oAuthProviders.map((provider) => (
          <Button
            key={provider.name}
            href={provider.redirect_uri}
            className='oauth-provider'
            title={provider.name}
            sx={{ width: '100%', mt: '16px' }}
            size='large'
            variant='outlined'
          >
            {'icon' in provider && <Img src={provider.icon} alt={provider.name} sx={{ width: '20px', mr: 1 }} />}
            <FormattedMessage id='loginWith' values={{ providerName: provider.name }} />
          </Button>
        ))}
      </div>
    </div>
  );
}

function assertNever(_: never): never {
  throw new Error('Unexpected value. Should have been never.');
}
