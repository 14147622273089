// Extracted to a module for easier testing
export function copyToClipboard(text: string): void {
  void navigator.clipboard.writeText(text);
}

export function canParseUrl(url: string): boolean {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl !== null) {
      return true;
    }
  } catch {
    return false;
  }

  return false;
}
