import * as React from 'react';
import type { PatternFormatProps } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import type { InputBaseComponentProps } from '@mui/material';

interface MaskedInputProps extends Omit<React.ComponentProps<typeof TextField>, 'format'> {
  format: string;
}

interface PatternFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
}

// This sub-component replaces the inner input component of the one provided by MUI
const PatternFormatCustom = React.forwardRef<PatternFormatProps, PatternFormatCustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={props.format}
      allowEmptyFormatting
    />
  );
});
PatternFormatCustom.displayName = 'PatternFormatCustom';

export default function MaskedInput({ format, ...rest }: MaskedInputProps): JSX.Element {
  return (
    <TextField
      {...rest}
      InputProps={{
        inputComponent: PatternFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
        inputProps: {
          format,
        },
      }}
    />
  );
}
