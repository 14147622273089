import log from 'loglevel';

declare global {
  interface Window {
    gianoLog: log.Logger;
  }
}
const logger = log.getLogger('giano');

// Set log level to allow only warn and error in production
if (process.env.NODE_ENV === 'production') {
  logger.setLevel('warn');
} else {
  logger.setLevel('debug');
}

window.gianoLog = logger;
export default logger;
