// Use https://github.com/pmndrs/zustand as a reference
import { create } from 'zustand';
import { getInitialLocale } from '../i18n/i18nProvider';

export interface GianoState {
  locale: string;
  updateLocale: (locale: string) => void;
  notification: string;
  updateNotification: (notification: string) => void;
  error: string;
  updateError: (error: string) => void;
}

const useGianoStore = create<GianoState>((set) => ({
  locale: getInitialLocale(),
  updateLocale: (locale: string) => {
    set({ locale });
  },
  notification: '',
  updateNotification: (notification: string) => {
    set({ notification });
  },
  error: '',
  updateError: (error: string) => {
    set({ error });
  },
}));

export default useGianoStore;
