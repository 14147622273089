import axios from 'axios';
import logger from '../logger';

interface CsrfResponse {
  csrf_token: string;
}

export default async function getCsrfToken(): Promise<null | string> {
  try {
    const {
      data: { csrf_token: token },
    } = await axios.get<CsrfResponse>('/auth/ui/csrf_token');
    logger.info('got csrf token');
    return token;
  } catch (error) {
    logger.error('error while getting csrf token:', error);
    return null;
  }
}
