import axios from 'axios';
import logger from '../logger';

export interface OAuthProvider {
  name: string;
  redirect_uri: string;
  description?: string;
  icon?: string;
}

type OAuthProvidersResponse = OAuthProvider[];

export async function getOAuthProviders(): Promise<OAuthProvidersResponse | { status: number }> {
  try {
    const { data, status } = await axios.get<OAuthProvidersResponse>('/auth/oauth/v1/providers');
    if (status === 200) {
      logger.info('got oauth providers');
      logger.debug(`configured oauth providers for realm: ${JSON.stringify(data)}`);
      return data;
    } else {
      logger.warn(`getOAuthProviders status is not 200, got status ${status}`);
      return { status };
    }
  } catch (error) {
    logger.error('getOAuthProviders error', error);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      return { status };
    }
    return { status: 500 };
  }
}
