import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useIntl } from 'react-intl';

interface InputPasswordProps {
  autoFocus?: boolean;
  autoComplete?: string;
  id?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const defaultProps: InputPasswordProps = {
  autoFocus: false,
  autoComplete: 'off',
  id: 'password',
  required: false,
  placeholder: '',
  value: '',
  label: '',
  disabled: false,
  error: undefined,
  onChange: () => {},
};

const InputPassword = ({
  autoFocus,
  autoComplete,
  id,
  required,
  placeholder,
  value,
  label,
  disabled,
  error,
  onChange,
}: InputPasswordProps): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };
  const intl = useIntl();

  return (
    <FormControl variant='outlined'>
      {typeof label === 'string' && label.length > 0 && (
        <InputLabel disabled={disabled} required={required} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        autoFocus={autoFocus}
        id={id}
        autoComplete={autoComplete}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        label={label}
        value={value}
        disabled={disabled}
        error={error !== undefined}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label={intl.formatMessage({ id: 'togglePassword' })}
              disabled={disabled}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <Visibility color='primary' /> : <VisibilityOff color='primary' />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error !== undefined && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

InputPassword.defaultProps = defaultProps;

export default InputPassword;
