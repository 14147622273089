import React, { useEffect, useState } from 'react';

import QRCode from 'qrcode.react';
import { Typography, Box, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormattedMessage, useIntl } from 'react-intl';

import type { TOTPRequest } from '../api/totp';
import { getTotpConfig, saveTotpConfig } from '../api/totp';
import LoadingButton from '../components/LoadingButton';
import useGianoStore from '../store/store';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard, canParseUrl } from '../helpers/dataProcessing';
import navigateTo from '../helpers/navigation';
import MaskedInput from '../components/MaskedInput';
import { PUBLIC_URL } from '../constants';
import logger from '../logger';

export default function TotpSetupForm(): JSX.Element {
  // get totpUri from the backend API and save it in the state
  const [totpConfig, setTotpConfig] = useState<TOTPRequest | null>(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const updateNotification = useGianoStore((state) => state.updateNotification);
  const updateError = useGianoStore((state) => state.updateError);
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted
    const fetchData = async (): Promise<void> => {
      const data = await getTotpConfig();
      if (data.status !== 200) {
        updateError('error');
      } else if (isMounted) {
        setTotpConfig(data);
      }
    };
    void fetchData();
    return () => {
      isMounted = false;
    };
  }, [updateError]);

  const [totpCode, setTotpCode] = useState('');

  useEffect(() => {
    // Basic validation of the totpCode ( exact length and only numbers)
    if (totpCode.length === 6 && /^\d+$/.test(totpCode)) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [totpCode]);

  const handleTotpCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTotpCode(event.target.value);
  };

  const handleTotpValidation = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (totpConfig?.uri !== undefined && totpConfig?.verification_code !== undefined && totpCode !== '') {
      setLoading(true);
      setSubmitEnabled(false);
      saveTotpConfig({ otp: totpCode, uri: totpConfig?.uri, verification_code: totpConfig?.verification_code })
        .then((res) => {
          if (res === null) {
            updateNotification('totpSetupSuccess');
            logger.info('TOTP setup success, redirecting to root');
            navigateTo(PUBLIC_URL);
          } else if ('next_action' in res) {
            if (res.next_action === null) {
              updateNotification('totpSetupSuccess');
              logger.info('TOTP setup success, redirecting to root');
              navigateTo(PUBLIC_URL);
            } else if (res.next_action.action === 'CHANGE_PASS') {
              updateNotification('successRedirectingToChangePassword');
              logger.info('TOTP setup success, redirecting to change password');
              navigate('/change-password');
            } else if (res.next_action.action === 'AUTHENTICATE') {
              updateError('unauthorizedRedirectingToLogin');
              logger.info('Failed to setup TOTP: unauthorized, redirecting to login');
              navigate('/login');
            }
          } else if ('errors' in res) {
            updateError(res.errors[0].detail);
          }
          setLoading(false);
        })
        .catch(() => {
          updateError('error');
          setLoading(false);
        });
    }
  };

  const handleVerificationCodeCopy = (): void => {
    if (totpConfig?.uri !== undefined) {
      copyToClipboard(getTotpSecretFromUri(totpConfig.uri));
    }
  };

  const getTotpSecretFromUri = (uri: string | null): string => {
    if (uri === null) {
      return '';
    }

    if (canParseUrl(uri)) {
      const parsed = new URL(uri);
      const secret = parsed.searchParams.get('secret');
      if (secret === null) {
        return '';
      } else {
        return secret;
      }
    } else {
      return '';
    }
  };

  return (
    <div className='form'>
      <Typography variant='h5' textAlign={'center'} mb={1}>
        <FormattedMessage id='totpSetupTitle' />
      </Typography>
      {totpConfig?.uri !== undefined ? (
        <form onSubmit={handleTotpValidation} autoComplete='off'>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='body1'>
              <FormattedMessage id='totpSetupInstructionsScanQr' />
            </Typography>
            <Box my={1}>
              <QRCode title={intl.formatMessage({ id: 'quickAuthSetupQRCode' })} value={totpConfig.uri} />
            </Box>
            <Typography variant='body1'>
              <FormattedMessage id='totpSetupInstructionsSetupManually' />
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>{getTotpSecretFromUri(totpConfig?.uri)}</Box>
              <IconButton
                aria-label={intl.formatMessage({ id: 'copyTheText' })}
                onClick={handleVerificationCodeCopy}
                onMouseDown={handleVerificationCodeCopy}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Box>
          <Box my={1}>
            <MaskedInput
              autoFocus
              format='### ###'
              label={intl.formatMessage({ id: 'sixDigitCode' })}
              sx={{ width: '100%' }}
              value={totpCode}
              onChange={handleTotpCodeChange}
              disabled={loading}
            />
          </Box>
          <LoadingButton disabled={!submitEnabled} loading={loading}>
            <FormattedMessage id='verifyTotp' />
          </LoadingButton>
        </form>
      ) : (
        <Typography variant='body1' textAlign={'center'}>
          <FormattedMessage id='loading' />
        </Typography>
      )}
    </div>
  );
}
