import React, { useCallback } from 'react';

import type { FormControlProps, SelectChangeEvent } from '@mui/material';
import { FormControl, Select, MenuItem } from '@mui/material';
import { AVAILABLE_LOCALES } from '../i18n/i18nProvider';
import useGianoStore from '../store/store';

interface LanguageSelectorProps {
  sx?: FormControlProps['sx'];
}

const defaultProps: LanguageSelectorProps = {
  sx: {},
};

const LanguageSelector = ({ sx }: LanguageSelectorProps): JSX.Element => {
  const locale: string = useGianoStore((state) => state.locale);
  const updateLocale = useGianoStore((state) => state.updateLocale);

  // Update the locale in localStorage when it changes
  React.useEffect(() => {
    localStorage.setItem('giano-locale', locale);
  }, [locale]);

  // Wrap the handler in useCallback so that it doesn't get recreated on every render
  const handleChange = useCallback(
    (event: SelectChangeEvent): void => {
      updateLocale(event.target.value);
    },
    [updateLocale]
  );

  const defaultStyle = { my: 1, minWidth: 120 };
  const style: FormControlProps['sx'] = sx ? { ...defaultStyle, ...sx } : defaultStyle;

  return (
    <FormControl sx={style} size='small'>
      <Select sx={{ backgroundColor: 'white' }} value={locale} onChange={handleChange}>
        {AVAILABLE_LOCALES.map((locale) => (
          <MenuItem key={locale.key} value={locale.key}>
            {locale.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

LanguageSelector.defaultProps = defaultProps;

export default LanguageSelector;
