import type { Translations } from './messages';
import messages from './messages';

export const AVAILABLE_LOCALES = [
  { key: 'en', name: '🇺🇸 English' },
  { key: 'it', name: '🇮🇹 Italiano' },
];

export const getInitialLocale = (): string => {
  const locale = window.localStorage.getItem('giano-locale');
  if (locale !== null && locale) {
    return maybeFindLocale(locale);
  }
  // Extract main language from browser locale (e.g. en-US => en)
  const browserLocale = navigator.language.split('-')[0];
  return maybeFindLocale(browserLocale);
};

const maybeFindLocale = (locale: string): string => {
  const availableLocale = AVAILABLE_LOCALES.find((l) => l.key === locale);
  // Check if provided locale has a translation
  if (availableLocale) {
    return availableLocale.key;
  }
  // Fallback to default locale
  return 'en';
};

export const getMessages = (locale: string): Translations | undefined => {
  if (locale in messages) {
    return messages[locale];
  }
  return undefined;
};

export const messageExists = (locale: string, key: string): boolean => {
  if (locale in messages) {
    return key in messages[locale];
  }
  return false;
};
