import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { verifyTotp } from '../api/totp';
import LoadingButton from '../components/LoadingButton';
import MaskedInput from '../components/MaskedInput';
import navigateTo from '../helpers/navigation';
import useGianoStore from '../store/store';
import { PUBLIC_URL } from '../constants';
import logger from '../logger';

const Form = styled('form')``;

export default function TotpValidate(): JSX.Element {
  const [totpCode, setTotpCode] = React.useState('');
  const [submitEnabled, setSubmitEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const updateNotification = useGianoStore((state) => state.updateNotification);
  const updateError = useGianoStore((state) => state.updateError);
  const navigate = useNavigate();
  const intl = useIntl();

  const handleTotpCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTotpCode(e.target.value);
  };

  React.useEffect(() => {
    // Basic validation of the totpCode ( exact length and only numbers)
    if (totpCode.length === 6 && /^\d+$/.test(totpCode)) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [totpCode]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e): void => {
    e.preventDefault();
    if (totpCode.length === 6) {
      setSubmitEnabled(false);
      setLoading(true);
      verifyTotp({ otp: totpCode })
        .then((res) => {
          if (res === null) {
            updateNotification('totpValidationSuccess');
            logger.info('TOTP validation success, redirecting to root');
            navigateTo(PUBLIC_URL);
          } else if ('next_action' in res) {
            if (res.next_action === null) {
              updateNotification('totpValidationSuccess');
              logger.info('TOTP validation success, redirecting to root');
              navigateTo(PUBLIC_URL);
            } else if (res.next_action.action === 'CHANGE_PASS') {
              updateNotification('successRedirectingToChangePassword');
              logger.info('TOTP validation success, redirecting to change password');
              navigate('/change-password');
            } else if (res.next_action.action === 'AUTHENTICATE') {
              updateError('unauthorizedRedirectingToLogin');
              logger.info('Failed TOTP validation: unauthorized, redirecting to login');
              navigate('/login');
            }
          } else if ('errors' in res) {
            updateError(res.errors[0].detail);
          }
          setLoading(false);
        })
        .catch(() => {
          updateError('error');
          setLoading(false);
        });
    }
  };

  return (
    <div className='form'>
      <Form
        onSubmit={handleSubmit}
        autoComplete='off'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '16px',
        }}
      >
        <Typography variant='h5' textAlign={'center'} mb={1}>
          <FormattedMessage id='totpValidateTitle' />
        </Typography>
        <MaskedInput
          autoFocus
          format='### ###'
          label={intl.formatMessage({ id: 'sixDigitCode' })}
          sx={{ width: '100%' }}
          value={totpCode}
          onChange={handleTotpCodeChange}
          disabled={loading}
        />
        <div>
          <LoadingButton disabled={!submitEnabled} loading={loading}>
            <FormattedMessage id='totpValidate' />
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
}
