import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MaybeFormattedMessage from './MaybeFormattedMessage';

interface ErrorDialogProps {
  title: string;
  message: string;
  buttonText: string;
  onClick: () => void;
}

const ErrorDialog = ({ title, message, buttonText, onClick }: ErrorDialogProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={true}
      BackdropProps={{ invisible: true }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
          gap: theme.spacing(1),
          [theme.breakpoints.up('sm')]: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
        }}
      >
        <FormattedMessage id={title} />
      </DialogTitle>
      {message !== '' && (
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <MaybeFormattedMessage id={message} />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button size='medium' variant='contained' onClick={onClick}>
          <FormattedMessage id={buttonText} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
