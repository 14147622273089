import axios from 'axios';
import logger from '../logger';
import type { AuthenticateAction, ChangePassAction, JsonErrorResponse } from './types';

// Response to a request for a TOTP token config
export interface TOTPRequest {
  status?: number;
  uri?: string;
  verification_code?: string;
}

// Object for a TOTP token config, with confirmation OTP.
export interface TOTPCreate {
  otp: string;
  uri: string;
  verification_code: string;
}

export type CreateSuccess = { next_action: ChangePassAction } | null;

export interface TOTPVerify {
  otp: string;
}

export async function getTotpConfig(): Promise<TOTPRequest> {
  try {
    const { data, status } = await axios.get<TOTPRequest>('/auth/api/v1/session/totp');
    if (status === 200) {
      logger.info('getTotpConfig success');
      return {
        status,
        ...data,
      };
    } else {
      logger.warn('getTotpConfig response is not 200', data);
      return { status };
    }
  } catch (error) {
    logger.error('getTotpConfig error', error);
    if (axios.isAxiosError(error)) {
      logger.error('getTotpConfig error', error);
      const status = error.response?.status ?? 500;
      return { status };
    }
    return { status: 500 };
  }
}

export async function saveTotpConfig(
  TOTPCreate: TOTPCreate
): Promise<CreateSuccess | AuthenticateAction | JsonErrorResponse> {
  try {
    const { data, status } = await axios.post<CreateSuccess>('/auth/api/v1/session/totp', TOTPCreate);
    if (status >= 200 && status < 400) {
      return data;
    } else {
      // process JSON error response
      return httpErrorToResult(status);
    }
  } catch (error) {
    logger.error('saveTotpConfig error', error);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      return httpErrorToResult(status);
    }
    return httpErrorToResult(500);
  }
}

export async function verifyTotp(
  TOTPVerify: TOTPVerify
): Promise<CreateSuccess | AuthenticateAction | JsonErrorResponse> {
  try {
    const { data, status } = await axios.post<CreateSuccess>('/auth/api/v1/session/totp/verify', TOTPVerify);
    if (status >= 200 && status < 400) {
      return data;
    } else {
      // process JSON error response
      return httpErrorToResult(status);
    }
  } catch (error) {
    logger.error('verifyTotp error', error);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      return httpErrorToResult(status);
    }
    return httpErrorToResult(500);
  }
}

const httpErrorToResult = (status: number): JsonErrorResponse | AuthenticateAction => {
  if (status === 401) {
    return { next_action: { action: 'AUTHENTICATE', reason: 'UNAUTHORIZED' } };
  }
  return {
    errors: [
      {
        detail: `HTTP error ${status}`,
        source: {
          pointer: '/data',
        },
        title: `Error ${status}`,
      },
    ],
  };
};
