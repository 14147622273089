import React from 'react';
import ErrorDialog from '../components/ErrorDialog';
import navigateTo from '../helpers/navigation';
import { PUBLIC_URL } from '../constants';

type oAuthError =
  | 'invalid_request'
  | 'invalid_client'
  | 'invalid_grant'
  | 'unauthorized_client'
  | 'unsupported_grant_type'
  | 'invalid_scope';

const Error = (): JSX.Element => {
  // get Error description from url
  const urlParams = new URLSearchParams(window.location.search);
  const error: oAuthError | null = urlParams.get('error') as unknown as oAuthError;
  const errorDescription = urlParams.get('error_description');
  return (
    <ErrorDialog
      title={error !== null ? error : 'error'}
      message={errorDescription ?? ''}
      buttonText='returnToHome'
      onClick={() => {
        navigateTo(PUBLIC_URL);
      }}
    />
  );
};

export default Error;
