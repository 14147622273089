import axios from 'axios';
import logger from '../logger';
import type { ChangePassAction, SetupTotpAction, VerifyTotpAction } from './types';

interface SessionResponse {
  token: string;
  next_action?: ChangePassAction | SetupTotpAction | VerifyTotpAction;
}

interface Authorized {
  kind: 'authorized';
  next_action?: ChangePassAction | SetupTotpAction | VerifyTotpAction;
  token: string;
}

interface Unauthorized {
  kind: 'unauthorized';
}

interface Error {
  kind: 'error';
  code: number;
}

export type SessionResult = Authorized | Unauthorized | Error;

export default async function createSession(username: string, password: string): Promise<SessionResult> {
  try {
    const { data, status } = await axios.post<SessionResponse>('/auth/api/v1/session/create', { username, password });
    if (status >= 200 && status < 400) {
      const response: Authorized = { kind: 'authorized', token: data.token };
      if (data.next_action) {
        response.next_action = data.next_action;
      }
      logger.info('createSession success');
      return response;
    } else {
      logger.error('createSession error', data);
      return httpErrorToResult(status);
    }
  } catch (error) {
    logger.error('createSession error', error);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      return httpErrorToResult(status);
    }
    return { kind: 'error', code: 500 };
  }
}

function httpErrorToResult(status: number): SessionResult {
  if (status >= 200 && status < 400) {
    throw Error('not an http error');
  } else if (status === 401 || status === 403) {
    return { kind: 'unauthorized' };
  } else {
    return { kind: 'error', code: status };
  }
}
