export type Translations = Record<string, string>;

const messages: Record<string, Translations> = {
  en: {
    username: 'Username',
    password: 'Password',
    login: 'Login',
    loginWith: 'Login with {providerName}',
    togglePassword: 'toggle password visibility',
    somethingWentWrong: 'Something went wrong',
    somethingWentWrongMessage:
      'Oops, something went wrong! Please try reloading the page to see if that fixes the issue. If the problem persists, please contact support for further assistance.',
    reload: 'Reload',
    notFoundtitle: 'Page not found',
    notFoundMessage: 'The page you are looking for does not exist.',
    returnToHome: 'Return to home',
    updatePassword: 'The system requires a password update',
    changePassword: 'Change password',
    totpSetupTitle: 'Setup two-factor authentication',
    totpSetupInstructionsScanQr: 'Scan the QR code below with your authenticator app',
    totpSetupInstructionsSetupManually: 'Or enter the code below manually',
    quickAuthSetupQRCode: 'Quick authentication setup QR code',
    totpValidateTitle: 'Validate two-factor authentication using the code from your authenticator app',
    totpValidate: 'Validate TOTP code',
    copyTheText: 'Copy the text',
    sixDigitCode: '6 digit code',
    verifyTotp: 'Verify TOTP code',
    loading: 'Loading...',
    oldPassword: 'Old password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    passwordsDoNotMatch: 'Passwords do not match',
    // Notifications and errors
    loginSuccess: 'Login successful',
    passwordChangeSuccess: 'Password changed successfully',
    successRedirectingToChangePassword: 'Success! Redirecting to change password...',
    successRedirectingToSetup2FA: 'Success! Redirecting to setup 2FA...',
    successRedirectingToValidate2FA: 'Success! Redirecting to validate 2FA...',
    unauthorizedRedirectingToLogin: 'Unauthorized, redirecting to login...',
    totpSetupSuccess: 'TOTP setup successfully',
    totpValidationSuccess: 'TOTP validation success',
    unauthorized: 'Invalid username or password',
    error: 'Unknown error',
    // Password policy errors
    MIN_LOWER: 'Minimum number of lower case chars is not respected',
    MAX_LOWER: 'Maximum number of lower case chars is not respected',
    MIN_UPPER: 'Minimum number of upper case chars is not respected',
    MAX_UPPER: 'Maximum number of upper case chars is not respected',
    MIN_DIGIT: 'Minimum number of digits is not respected',
    MAX_DIGIT: 'Maximum number of digits is not respected',
    MIN_SPECIAL: 'Minimum number of special chars is not respected',
    MAX_SPECIAL: 'Maximum number of special chars is not respected',
    MIN_LENGTH: 'Minimum length is not respected',
    MAX_LENGTH: 'Maximum length is not respected',
    HISTORY: 'Password already used',
    // oAuth errors:
    invalid_request: 'Invalid request',
    invalid_client: 'Invalid client',
    invalid_grant: 'Invalid grant',
    unauthorized_client: 'Unauthorized client',
    unsupported_grant_type: 'Unsupported grant type',
    invalid_scope: 'Invalid scope',
  },
  it: {
    username: 'Nome utente',
    password: 'Password',
    login: 'Accedi',
    loginWith: 'Accedi con {providerName}',
    togglePassword: 'Mostra/nascondi password',
    somethingWentWrong: 'Qualcosa è andato storto',
    somethingWentWrongMessage:
      'Oops! Qualcosa è andato storto! Prova a ricaricare la pagina per vedere se questo risolve il problema. Se il problema persiste, contatta il supporto per ulteriore assistenza.',
    reload: 'Ricarica',
    notFoundtitle: 'Pagina non trovata',
    notFoundMessage: 'La pagina che stai cercando non esiste.',
    returnToHome: 'Torna alla home',
    updatePassword: 'Il sistema richiede un aggiornamento della password',
    changePassword: 'Cambia password',
    totpSetupTitle: 'Configura autenticazione a due fattori',
    totpSetupInstructionsScanQr: 'Scansiona il codice QR sottostante con la tua app di autenticazione',
    totpSetupInstructionsSetupManually: 'Oppure inserisci il codice sottostante manualmente',
    quickAuthSetupQRCode: "Codice QR per la configurazione rapida dell'autenticazione",
    totpValidateTitle: "Convalida l'autenticazione a due fattori utilizzando il codice della tua app di autenticazione",
    totpValidate: 'Convalida codice TOTP',
    copyTheText: 'Copia il testo',
    sixDigitCode: 'Codice a 6 cifre',
    verifyTotp: 'Verifica codice TOTP',
    loading: 'Caricamento...',
    oldPassword: 'Vecchia password',
    newPassword: 'Nuova password',
    repeatPassword: 'Ripeti password',
    passwordsDoNotMatch: 'Le password non corrispondono',
    // Notifications and errors
    loginSuccess: 'Accesso riuscito',
    passwordChangeSuccess: 'Password cambiata con successo',
    successRedirectingToChangePassword: 'Successo! Reindirizzamento al cambio password...',
    successRedirectingToSetup2FA: 'Successo! Reindirizzamento alla configurazione 2FA...',
    successRedirectingToValidate2FA: 'Successo! Reindirizzamento alla convalida 2FA...',
    unauthorizedRedirectingToLogin: 'Non autorizzato, reindirizzamento al login...',
    totpSetupSuccess: 'TOTP configurato con successo',
    totpValidationSuccess: 'TOTP convalidato con successo',
    unauthorized: 'Nome utente o password errati',
    error: 'Errore sconosciuto',
    // Password policy errors
    MIN_LOWER: 'Il numero minimo di caratteri minuscoli non è rispettato',
    MAX_LOWER: 'Il numero massimo di caratteri minuscoli non è rispettato',
    MIN_UPPER: 'Il numero minimo di caratteri maiuscoli non è rispettato',
    MAX_UPPER: 'Il numero massimo di caratteri maiuscoli non è rispettato',
    MIN_DIGIT: 'Il numero minimo di cifre non è rispettato',
    MAX_DIGIT: 'Il numero massimo di cifre non è rispettato',
    MIN_SPECIAL: 'Il numero minimo di caratteri speciali non è rispettato',
    MAX_SPECIAL: 'Il numero massimo di caratteri speciali non è rispettato',
    MIN_LENGTH: 'La lunghezza minima non è rispettata',
    MAX_LENGTH: 'La lunghezza massima non è rispettata',
    HISTORY: 'Password già utilizzata',
    // oAuth errors:
    invalid_request: 'Richiesta non valida',
    invalid_client: 'Client non valido',
    invalid_grant: 'Concessione non valida',
    unauthorized_client: 'Client non autorizzato',
    unsupported_grant_type: 'Tipo di concessione non supportato',
    invalid_scope: 'Ambito non valido',
  },
};

export default messages;
