import React from 'react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

import LanguageSelector from './components/LanguageSelector';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { getMessages } from './i18n/i18nProvider';
import useGianoStore from './store/store';
import Root from './routes/Root';
import Login from './routes/Login';
import ChangePassword from './routes/ChangePassword';
import TotpConfig from './routes/TotpConfig';
import Error from './routes/Error';
import ErrorDialog from './components/ErrorDialog';
import { Alert, Box, Snackbar } from '@mui/material';
import TotpValidate from './routes/TotpValidate';
import navigateTo from './helpers/navigation';
import { PUBLIC_URL } from './constants';
import MaybeFormattedMessage from './components/MaybeFormattedMessage';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      errorElement: (
        <ErrorDialog
          title='notFoundtitle'
          message='notFoundMessage'
          buttonText='returnToHome'
          onClick={() => {
            navigateTo(PUBLIC_URL);
          }}
        />
      ),
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'change-password',
      element: <ChangePassword />,
    },
    {
      path: 'totp-config',
      element: <TotpConfig />,
    },
    {
      path: 'validate-totp',
      element: <TotpValidate />,
    },
    {
      path: 'error',
      element: <Error />,
    },
  ],
  {
    basename: '/auth/ui',
  }
);

export default function App({ csrfToken }: { csrfToken: null | string }): JSX.Element {
  const locale = useGianoStore((state) => state.locale);
  const notification = useGianoStore((state) => state.notification);
  const updateNotification = useGianoStore((state) => state.updateNotification);
  const error = useGianoStore((state) => state.error);
  const updateError = useGianoStore((state) => state.updateError);
  const messages = getMessages(locale);

  return (
    <>
      {/* Set z-index 1400 to override z-index 1300 of <Dialog> component */}
      <Box sx={{ position: 'fixed', width: '100%', top: 0, textAlign: 'right', zIndex: 1400 }}>
        <LanguageSelector sx={{ alignSelf: 'flex-end', mx: 1 }} />
      </Box>
      <div className='App'>
        <IntlProvider messages={messages} locale={locale} defaultLocale='en'>
          {csrfToken === null ? (
            <ErrorDialog
              title='somethingWentWrong'
              message='somethingWentWrongMessage'
              buttonText='reload'
              onClick={() => {
                location.reload();
              }}
            />
          ) : (
            <RouterProvider router={router} />
          )}
          <Snackbar
            open={notification !== ''}
            autoHideDuration={6000}
            onClose={() => {
              updateNotification('');
            }}
            message={notification !== '' && <FormattedMessage id={notification} />}
          />
          {error !== '' && (
            <Snackbar
              open={true}
              autoHideDuration={6000}
              onClose={() => {
                updateError('');
              }}
            >
              <Alert severity='error'>
                <span>{error !== '' && <MaybeFormattedMessage id={error} />}</span>
              </Alert>
            </Snackbar>
          )}
        </IntlProvider>
      </div>
    </>
  );
}
