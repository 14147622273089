import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import changePassword from '../api/changePassword';
import InputPassword from '../components/InputPassword';
import LoadingButton from '../components/LoadingButton';
import navigateTo from '../helpers/navigation';
import useGianoStore from '../store/store';
import { PUBLIC_URL } from '../constants';
import logger from '../logger';

const Form = styled('form')``;

export default function ChangePassword(): JSX.Element {
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');
  const [submitEnabled, setSubmitEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const updateNotification = useGianoStore((state) => state.updateNotification);
  const updateError = useGianoStore((state) => state.updateError);
  const navigate = useNavigate();
  const intl = useIntl();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target;
    switch (id) {
      case 'password':
        setPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'repeatNewPassword':
        setRepeatNewPassword(value);
        break;
    }
  };

  React.useEffect(() => {
    if (password && newPassword && repeatNewPassword && newPassword === repeatNewPassword) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [password, newPassword, repeatNewPassword]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e): void => {
    e.preventDefault();
    if (password && newPassword && repeatNewPassword && newPassword === repeatNewPassword) {
      setSubmitEnabled(false);
      setLoading(true);
      changePassword(password, newPassword)
        .then((res) => {
          if (res === null) {
            updateNotification('passwordChangeSuccess');
            logger.info('Password change success, redirecting to root');
            navigateTo(PUBLIC_URL);
          } else if ('next_action' in res) {
            if (res.next_action === null) {
              updateNotification('passwordChangeSuccess');
              logger.info('Password change success, redirecting to root');
              navigateTo(PUBLIC_URL);
            } else if (res.next_action.action === 'SETUP_TOTP') {
              updateNotification('successRedirectingToSetup2FA');
              logger.info('Password change success, 2FA setup required, redirecting to setup');
              navigate('/totp-config');
            } else if (res.next_action.action === 'AUTHENTICATE') {
              updateError('unauthorizedRedirectingToLogin');
              logger.info('Failed password change: unauthorized, redirecting to login');
              navigate('/login');
            }
          } else if ('errors' in res) {
            updateError(res.errors[0].detail);
          } else if ('error' in res) {
            updateError(res.error);
          }
          setLoading(false);
        })
        .catch((res) => {
          if ('errors' in res) {
            updateError(res.errors[0].detail);
          } else if ('error' in res) {
            updateError(res.error);
          } else {
            updateError('error');
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className='form'>
      <Form
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '16px',
        }}
      >
        <Typography variant='h5' textAlign={'center'} mb={1}>
          <FormattedMessage id='updatePassword' />
        </Typography>
        <InputPassword
          autoFocus
          id='password'
          value={password}
          onChange={handlePasswordChange}
          required
          label={intl.formatMessage({ id: 'oldPassword' })}
          disabled={loading}
        />
        <InputPassword
          id='newPassword'
          value={newPassword}
          onChange={handlePasswordChange}
          required
          label={intl.formatMessage({ id: 'newPassword' })}
          disabled={loading}
        />
        <InputPassword
          id='repeatNewPassword'
          value={repeatNewPassword}
          onChange={handlePasswordChange}
          required
          label={intl.formatMessage({ id: 'repeatPassword' })}
          disabled={loading}
          error={newPassword !== repeatNewPassword ? intl.formatMessage({ id: 'passwordsDoNotMatch' }) : undefined}
        />
        <div>
          <LoadingButton disabled={!submitEnabled} loading={loading}>
            <FormattedMessage id='changePassword' />
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
}
