import React from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

interface LoadingButtonProps {
  disabled?: boolean;
  loading?: boolean;
  type: string;
  children: React.ReactNode;
}

// Default props:
const defaultProps: LoadingButtonProps = {
  disabled: false,
  loading: false,
  type: 'submit',
  children: null,
};

const LoadingButton = ({ disabled, loading, children }: LoadingButtonProps): JSX.Element => {
  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Button sx={{ width: '100%' }} size='large' variant='contained' type='submit' disabled={disabled}>
        <Box visibility={loading === true ? 'hidden' : 'visible'}>{children}</Box>
      </Button>
      {loading === true && (
        <CircularProgress
          size={16}
          color='inherit'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-8px',
            marginLeft: '-8px',
          }}
        />
      )}
    </Box>
  );
};

LoadingButton.defaultProps = defaultProps;

export default LoadingButton;
