import axios from 'axios';
import logger from '../logger';
import type { AuthenticateAction, JsonErrorResponse, SetupTotpAction } from './types';

export interface ChangePasswordRequest {
  old_password: string;
  new_password: string;
}

export interface PolicyError {
  error:
    | 'MIN_LOWER'
    | 'MAX_LOWER'
    | 'MIN_UPPER'
    | 'MAX_UPPER'
    | 'MIN_DIGIT'
    | 'MAX_DIGIT'
    | 'MIN_SPECIAL'
    | 'MAX_SPECIAL'
    | 'MIN_LENGTH'
    | 'MAX_LENGTH'
    | 'HISTORY'
    | 'error';
}

export type ChangePassSuccess = { next_action: SetupTotpAction } | null;

export type ChangePassError = JsonErrorResponse | PolicyError;

export default async function changePassword(
  oldPassword: ChangePasswordRequest['old_password'],
  newPassword: ChangePasswordRequest['new_password']
): Promise<ChangePassSuccess | AuthenticateAction | ChangePassError> {
  try {
    const { data } = await axios.post<ChangePassSuccess>('/auth/api/v1/session/change_pass', {
      old_password: oldPassword,
      new_password: newPassword,
    });
    logger.info('changePassword success');
    return data;
  } catch (error) {
    logger.error('changePassword error', error);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      if (status === 401) {
        return { next_action: { action: 'AUTHENTICATE', reason: 'UNAUTHORIZED' } };
      }
      return error.response?.data ?? { error: 'error' };
    }
    return {
      error: 'error',
    };
  }
}
