import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messageExists } from '../i18n/i18nProvider';
import useGianoStore from '../store/store';

// Inherit the type of the props of FormattedMessage but make the id required
type MaybeFormattedMessageProps = Omit<React.ComponentProps<typeof FormattedMessage>, 'id'> & { id: string };

// This component is used to avoid the error "Missing message: "id" was not provided." when the id is not present in the messages
const MaybeFormattedMessage = ({ id, ...other }: MaybeFormattedMessageProps): JSX.Element => {
  const locale = useGianoStore((state) => state.locale);
  return messageExists(locale, id) ? <FormattedMessage id={id} {...other} /> : <>{id}</>;
};

export default MaybeFormattedMessage;
