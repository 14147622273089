import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import getCsrfToken from './api/csrfToken';
import './index.css';
import App from './App';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import './logger'; // bundle logger

// Override default theme settings
const primaryColor = '#1C4E9D';
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: primaryColor,
          fontWeight: 500,
        },
      },
    },
  },
});

const setCsrfToken = (token: null | string): void => {
  if (token != null) {
    const header = 'x-csrf-token';
    axios.defaults.headers.common[header] = token;
    renderUi(token);
  } else {
    renderUi(null);
  }
};

const renderUi = (csrfToken: null | string): void => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App csrfToken={csrfToken} />
      </ThemeProvider>
    </React.StrictMode>
  );
};

getCsrfToken().then(setCsrfToken, null);
